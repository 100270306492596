.payment-container {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
}
.bill-container {
  /* margin-top: 65px; */
  width: 100%;
  min-height: 89vh;
  background-color: #fff;
  border-radius: 6px;
  /* overflow-x: auto; */
}

.card__item {
  padding: 25px 10px;
  /* box-shadow: 0px 2px 12px #ebebeb; */
  /* box-shadow: 5px 5px 16px #e3e3e3, -5px -5px 16px #ffffff; */
  border-radius: 7px;
  border-bottom: 1px solid #ebebeb;
}

.cart__header {
  color: #131523;
}

.cart__itemContainer {
  /* height: 90vh; */
  position: relative;
  max-height: 82vh;
  overflow-y: auto;
}

.cart__itemContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cart__itemContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.cart__priceContainer {
  display: flex;
  align-items: center;
}

.cart__title {
  font-family: boldFont;
  font-size: 20px;
  color: #616161;
}

.cart_headContainer {
  box-shadow: 0px 2px 12px #ebebeb;
}

.cart__price {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #616161;
  font-family: boldFont;
}

.positioned__cartBtn {
  position: sticky;
  /* margin-top: 10px; */
  bottom: 0;
}

.shipform--Header {
  color: #707070;
  font-family: boldFont;
  margin-bottom: 30px;
}

.inputBox .MuiInput-root {
  width: auto !important;
  margin: 0;
}

.inputBox .MuiInputBase-input {
  width: 100% !important;
}

.remove__btn {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  text-transform: initial !important;
  font-size: 18px !important;
  padding: 5px 16px !important;
  color: #cc0000 !important;
  font-family: semiFont !important;
}

.button__row {
  display: flex;
  align-items: center;
}

.cart__detailsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-label {
  color: #707070;
  font-family: mediumFont;
  padding-left: 3px;
}
.covid-checkbox {
  color: #393939;
  font-family: mediumFont;
  font-size: 16px;
}
.pincodelabel {
  color: #393e46;
  font-family: mediumFont;
}
.order-Title {
  font-family: boldFont;
  color: #393e46;
  font-size: 16px;
}
.bIZQns {
  justify-content: space-between;
  align-items: center !important;
  /* background-color: red !important; */
}
.jAFJSE {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.bZxlrS {
  margin-bottom: 0 !important;
}

.credit-input-field {
  border: none;
  padding: 15px 10px;
  background-color: transparent;
}
.paypaltext {
  color: #393e46;
  font-family: mediumFont;
  font-size: 11px;
}
.payment-checkbox {
  color: #393e46;
  font-family: mediumFont;
  font-size: 11px;
  vertical-align: middle;

  margin-bottom: 5px;
}
.payment-info {
  color: #393e46;
  font-family: regularFont;
  font-size: 13px;
  text-align: left;
}
.Payment__button {
  width: 100%;
  background-color: #4059e2;
}

.patient--maincontainer {
  margin-top: 30px;
  min-height: 100vh;
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid #4059e2;
  margin-bottom: 30px;
  overflow-y: scroll;
  padding: 30px;
}
.user-proceed-button {
  color: white !important;
  font-size: 16px !important;
  font-family: boldFont !important;
  background-color: #1565d8 !important;
  text-transform: initial !important;
}
.user-appointment-button {
  color: #4059e2 !important;
  font-size: 16px !important;
  font-family: mediumFont !important;
  background-color: white !important;
  padding: 15px 100px !important;
  border: 1px solid #4059e2 !important;
}

.userlist-form-input {
  padding: 25px 15px;
  background-color: white !important;
}

.userlist-error-position {
  position: absolute;
  right: 10px;
  bottom: -9px;
  padding: 0px 10px;
  background: linear-gradient(#ffffff, #f8f8f8);
}

.userlist-error-position p {
  color: red;
  font-family: mediumFont;
  font-size: 14px;
}
/*background: linear-gradient(#ffffff, #f8f8f8);*/
.userList-card {
  margin-top: 50px;
  background-color: white;
  /* border: 0px solid #4059e2; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px -2px 12px #ebebeb;
  /* transition: 0.3s; */
}

.list-submit--btn {
  padding: 25px;
  margin: 30px 0px;
}
.shipform--Header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.proceed-text1 {
  margin: 15px;
  font-size: 20px;
  font-family: mediumFont;
  color: #05060d;
}

@media only screen and (max-width: 992px) {
  .row-split {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .positioned__cartBtn {
    position: sticky;
    /* margin-top: 10px; */
    bottom: 0;
    /* z-index: 1000; */
  }
}

@media only screen and (max-width: 556px) {
  .shipform--Header {
    font-size: 22px;
  }

  .cart__container {
    padding: 15px 20px !important;
  }
}
.proceed-text1 {
  text-align: center;
}

@media screen and (max-width: 520px) {
  .cart__detailsSection {
    flex-direction: column;
  }

  .user-proceed-button {
    margin-top: 10px !important;
  }
}
