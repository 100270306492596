.primary-bg {
  background-color: #1565d8;
}

.header__txt {
  font-size: 32px;
  color: #616161;
  font-family: boldFont;
}

.primary__btn {
  background-color: #1565d8 !important;
  text-transform: inherit !important;
  font-size: 18px !important;
  font-family: semiFont !important;
}

.primary__btn--disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  text-transform: inherit !important;
  font-size: 18px !important;
  font-family: semiFont !important;
}

.center__items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.regularFont {
  font-family: regularFont !important;
}

.mediumFont {
  font-family: mediumFont !important;
}

.boldFont {
  font-family: boldFont !important;
}

.semiFont {
  font-family: semiFont !important;
}

.italicFont {
  font-family: italicFont !important;
}

.hkRegularFont {
  font-family: hkRegularFont !important;
}

.hkBoldFont {
  font-family: hkBoldFont !important;
}
