* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus {
  border: 1px solid #1565d8 !important;
}

.css-14el2xx-placeholder {
  color: #959ead !important;
  font-family: semiFont;
}

input:focus-visible {
  outline: none !important;
}

label {
  margin-bottom: 0 !important;
}

#inputDropdown {
  font-family: MediumFont !important;
  color: rgb(24, 59, 86);
}

#inputDropdown div div > .css-qc6sy-singleValue {
  font-family: mediumFont;
  color: rgb(24, 59, 86);
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  content: "" !important;
  border: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #959ead !important;
  font-family: semiFont;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #959ead !important;
  font-family: semiFont;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #959ead !important;
  font-family: semiFont;
}

textarea:focus-visible {
  outline: none !important;
}

.Toastify__toast {
  background: #4c4d50;
}

.Toastify__toast-body {
  font-family: BoldFont;
}

.Toastify__close-button {
  align-self: center;
  /* margin-top: -1px; */
  margin-right: 10px;
}

@font-face {
  font-family: "boldFont";
  src: local("boldFont"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "regularFont";
  src: local("regularFont"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "mediumFont";
  src: local("mediumFont"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "semiFont";
  src: local("semiFont"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "italicFont";
  src: local("italicFont"),
    url(./assets/fonts/Poppins-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "hkBoldFont";
  src: local("hkBoldFont"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "hkRegularFont";
  src: local("hkRegularFont"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
