h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0 !important;
}

*,
::after,
::before {
  box-sizing: none !important;
}

.login__quotes {
  margin-bottom: 15px;
}

.login__sideContent {
  min-height: 100vh;
  width: 100%;
  font-family: hkRegularFont;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__sideSection {
  width: 65%;
}

.login__sideText {
  color: #ffffff;
  letter-spacing: 0.17px;
  /* line-height: 1.7; */
  /* text-align: center; */
}

.login__authorSection {
  display: flex;
  align-items: center;
  margin-top: 70px;
}

.login__authorName {
  margin-right: 30px;
  color: #f3f3f3;
}

.login__authorSign {
  display: flex;
  align-items: center;
}

.login__container {
  display: flex;
}

.login__section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__header {
  font-size: 32px;
  color: #616161;
}

.inputBox {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  position: relative;
}

.login__label {
  color: #5a7184;
  font-size: 18px;
}

.inputBox input {
  padding: 15px;
  border: 1px solid #c3cad9;
  border-radius: 8px;
  width: 320px;
  margin-top: 10px;
  color: #183b56;
  font-size: 19px;
  font-family: mediumFont;
  transition: all 0.3s;
  outline: none;
}
.inputBox .MuiInputBase-input.MuiInput-input.Mui-disabled.Mui-disabled {
  background-color: hsl(0, 0%, 95%);
}

.login__options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login__forget {
  color: #1565d8;
  font-family: semiFont;
  user-select: none;
  cursor: pointer;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1565d8 !important;
}

.login__options .MuiFormControlLabel-root {
  color: #5a718499 !important;
  font-family: semiFont !important;
  font-size: 16px !important;
  user-select: none !important;
}

.login__btn {
  width: 100% !important;
  background-color: #1565d8 !important;
  padding: 15px !important;
  border-radius: 8px !important;
  margin-top: 20px !important;
  text-transform: initial !important;
  font-family: boldFont !important;
  font-size: 18px !important;
}

.login__redirect {
  display: flex;
  align-items: center;
  user-select: none;
  /* justify-content: space-between; */
  margin-top: 20px;
}

.create__accText {
  font-family: semiFont;
  color: #5a7184;
  font-size: 15px;
}
.create__acc {
  color: #1565d8;
  font-family: boldFont;
  margin-left: 20px;
  cursor: pointer;
}

.password__icon {
  position: absolute;
  right: 15px;
  bottom: 18px;
}

.login__logo {
  width: 220px;
  height: 88px;
}

.err {
  position: absolute;
  bottom: -8px;
  right: 10px;
  background-color: #fff;
  font-family: semiFont;
  color: #cc0000;
  padding: 0 5px;
  margin: 0;
  font-size: 15px;
}

.forget__header {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 944px) {
  .login__container {
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
  }

  .login__section {
    margin: 30px 0;
  }

  .login__sideContent {
    min-height: 100%;
    padding: 30px 0;
    /* height: 100%; */
    /* height: 100% !important; */
  }

  .login__authorSection {
    flex-direction: column;
    margin-top: 30px;
  }

  .login__authorName {
    margin: 0;
  }
}
