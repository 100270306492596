.home__container {
  /* background-color: #fff; */
  min-height: 90vh;
  width: 100%;
}

.home__banner {
  height: 500px !important;
  /* background-color: wheat; */
  position: relative;
}

.home__bannerImage {
  background-size: cover;
  width: 100%;
  height: 500px;
  border-radius: 6px;
  filter: revert;
}

.home__bannerBtn {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.home__bannerBtn .banner__btn {
  background-color: #1565d8 !important;
  padding: 10px 30px !important;
  text-transform: inherit !important;
  border-radius: 0 !important;
}

.home__reportContainer {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.status__container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.reports__container {
  width: 68.5%;
}

.status__itemsConatiner {
  background-color: #fff !important;
  border-radius: 6px;
  padding: 10px;
}

.status__itemsConatiner:not(:first-of-type) {
  margin-top: 15px;
}

.status__detailsRow {
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.status__title {
  color: #7e84a3;
  font-family: boldFont;
  font-size: 17px;
}

.status__count {
  font-size: 25px;
  color: #131523;
  font-family: boldFont;
}

.reports__container {
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
}

.report__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2e2a60;
  font-size: 22px;
  font-family: semiFont;
}

.reports__details {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.reports__details-data {
  display: flex;
  padding: 15px 0 15px 5px;
  align-items: center;
  border-top: 2px solid #eeeeee;
  border-bottom: none;
  cursor: pointer;
}

.reports__details-data:last-of-type {
  border-bottom: 2px solid #eeeeee;
}

.reports__details-data:hover {
  background-color: rgb(240, 240, 240);
}

.report__headerTitle {
  color: #9b9797;
  font-family: semiFont;
  font-size: 18px;
}

.report__data {
  color: #3d3d3d;
  word-break: break-word;
  font-family: mediumFont;
  font-size: 17px;
}

.name {
  width: 30%;
}

.package {
  width: 50%;
}

.days {
  width: 20%;
}

@media screen and (max-width: 1142px) {
  .home__reportContainer {
    flex-direction: column;
  }

  .reports__container {
    margin-top: 15px;
    width: 100%;
  }

  .status__container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }

  .status__container .status__itemsConatiner {
    width: 32%;
    margin-top: 0;
  }
}

@media screen and (max-width: 550px) {
  .status__container {
    flex-direction: column;
  }

  .status__container .status__itemsConatiner {
    width: 100%;
  }

  .status__itemsConatiner:not(:first-of-type) {
    margin-top: 15px;
  }
}
