.sidebar__container {
  margin: 0 auto;
  position: relative;
  background-color: #d4e2f0;
  min-height: 100vh;
}

.sidebar__headerWrapper {
  position: sticky;
  width: 100%;
  z-index: 1001;
  top: 0;
}

.sidebar__header {
  box-shadow: 0px 1px 4px #15223214;
  /* max-width: 1440px; */
  background-color: #fff;
  margin: auto;
  /* position: fixed; */
}

.sidebar__headerContainer {
  /* width: 98%; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.sidebar__logoSection {
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
}

.sidebar__logo {
  width: 200px;
  /* margin-left: 20px; */
  /* height: 50px; */
}

.sidebar__menu {
  /* display: none; */
  cursor: pointer;
}

.sidebar__userDetails {
  display: flex;
  align-items: center;
}

.sidebar__userDropdown {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.user__avatarContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.user__avatar {
  width: 48px;
  height: 48px;
}

.user__dropdown {
  display: flex;
  align-items: center;
  user-select: none;
  margin-left: 15px;
  position: relative;
}

.user__name {
  color: #131523;
  margin-right: 10px;
}

.user__dropdownData {
  position: absolute;
  width: 150px;
  /* width: inherit; */
  top: 40px;
  margin-left: -10px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 3px 10px #00000029;
  z-index: 500;
}

.test__listdrop {
  padding: 10px;
  text-decoration: none;
  color: #131523;
  font-family: semiFont;
}

.test__listdrop:hover {
  padding: 10px;
  text-decoration: none;
  color: #131523;
  font-family: semiFont;
}

.sidebar__section {
  width: 250px;
  position: fixed;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  /* border-right: 0.2px solid #dae4ef; */
  top: 0;
  bottom: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar__section.active {
  /* left: 0; */
  transition: left 0.5s;
}

.sidebar__list {
  margin-top: 80px;
}

.sidebar__title,
.sidebar__title--scaled {
  margin-left: 25px;
  font-family: boldFont;
  font-size: 16px;
  font-weight: normal;
  transition: 0.3s;
}

.sidebar__title--scaled {
  transform: translateX(5px);
}

.sidebar__icons {
  margin-left: 10px;
}

.logout__btn {
  background-color: #fff !important;
  /* padding: 15px 5px; */
  margin: 0 auto !important;
  color: rgba(23, 23, 37, 0.7) !important;
  width: 100% !important;
  text-transform: initial !important;
  box-shadow: none !important;
  font-family: "boldFont" !important;
  font-size: 16px !important;
  font-weight: normal;
  margin-top: -7px !important;
}

.logout__btn .MuiButton-label {
  justify-content: flex-start;
  padding: 15px 5px;
  margin-left: 2px;
}

.logout__btn .MuiButton-label .MuiButton-startIcon {
  margin-right: 0 !important;
}

.logout__btn:hover {
  color: rgba(23, 23, 37, 0.7);
  box-shadow: none !important;
}

@media screen and (min-width: 768px) {
  .sidebar__section {
    width: 250px;
    position: fixed;
    height: 100%;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    /* border-right: 0.2px solid #dae4ef; */
    top: 0;
    bottom: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0.3s ease;
  }

  .sidebar__section--mini {
    width: 65px;
    position: fixed;
    height: 100%;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    /* border-right: 0.2px solid #dae4ef; */
    top: 0;
    bottom: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0.3s ease;
  }

  .safe__container {
    margin-left: 250px;
    padding: 1.1%;
    transition: margin-left 0.3s ease;
  }

  .safe__container--mini {
    margin-left: 65px;
    padding: 1.1%;

    transition: margin-left 0.3s ease;
  }
}

@media all and (max-width: 768px) {
  .sidebar__logo {
    margin-left: 20px;
  }

  .sidebar__section {
    background-color: #fff;
    width: 250px;
    position: fixed;
    opacity: 1;
    left: 0%;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 50;
    transition: all 0.3s ease;
    overflow-y: auto;
  }
  /* 
  .sidebar__section.active {
    left: 0;
    transition: 0.5s;
    height: 100%;
  } */

  .safe__container {
    margin-left: 0;
    padding: 1.1%;
  }

  .safe__container--mini {
    margin-left: 0;
    padding: 1.1%;
  }

  .sidebar__section--mini {
    left: -100%;
    display: none;
    opacity: 0;
    /* width: 0; */
    /* height: 100%; */
    transition: all 0.3s ease;
  }
}

@media all and (max-width: 530px) {
  .safe__container {
    margin-left: 0;
    padding: 2%;
  }

  .safe__container--mini {
    margin-left: 0;
    padding: 2%;
  }

  .sidebar__logo {
    margin-left: 10px;
    width: 130px;
  }
}
